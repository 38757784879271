import React from "react"
import heroImage from "../../assets/images/press-media-banner.jpg"

const PressMedia = ({ pageContext: { translate } }) => {
  return (
    <>
      {
        <div className="pressMedia_page">
          <div className="content">
            <div className="heading">
              <h1 className="heading-h1 semi text-darker mb-3 mb-lg-4">
                {translate.t?.press_and_media}
              </h1>
              <p className="paragraph-p1 mb-0">
                {translate.t?.eraofwe_helps_place_estate}
              </p>
            </div>
            <div className="banner-wrapper">
              <img src={heroImage} alt="Hero"></img>
            </div>
            <div className="content-wrapper">
              <p className="mb-4 d-none d-md-block author-text">
                Karlstad, Sweden -- 21 June 2021
              </p>
              <h2 className="bold">
                Era of We Launches Platform to Connect and Democratise the
                Coffee Supply Chain and ShiftValue Back to the Coffee Growers
              </h2>
              <div className="content-text">
                Today, Sweden-based Era of We announces the world’s first
                end-to-end coffee industry digital service that connects all
                stakeholders in the coffee supply chain– an innovative concept
                that will result in a more equitable and sustainable business
                model powered by transparency and high-quality products from
                healthier brands. Löfbergs, one of the largest family-owned
                coffee roasters in the Nordic countries, will debut as the first
                coffee roaster on the platform.
                <br />
                <br />
                Powered by technology and community, the Era of We platform will
                establish a digital global marketplace and social networking
                space that will promote direct relationships between coffee
                producers(farmers), facilitators, roasters and consumers and
                improve traceability of the brew through a newopen ecosystem.
                <br />
                <br />
                The platform will empower coffee growers, regardless of size, to
                build their own coffee brands and market directly to both
                roasters and consumers, thus creating a more efficient and
                equitable supply chain where farmers can set their own prices.
                Despite the boom in value downstream, coffee growers and producers
                continue to face an unprecedented cost-squeeze crisis that
                threatens global supply and threatens the livelihoods of
                small-scale farmers around the world. Reports suggest{" "}
                <a
                  href="https://www.fairtradeamerica.org/news-insights/we-love-coffee-are-we-willing-to-pay-the-price/"
                  target="_blank"
                  rel="noreferrer"
                >
                  nearly 61% of producers sell their coffee at prices below the
                  cost of production
                </a>{" "}
                – leaving many farmers struggling to survive. As a result, the
                children of these farmers are unwilling to continue the trade,
                which puts coffee at a risk for labour shortage and jeopardises
                the world’s supply of coffee.
                <br />
                <br />
                “We want to democratise the supply chain through transparency
                and collaboration,” said Martin Löfberg, founder and chairman of
                Era of We. “By helping farmers create individual brands based on
                their distinct coffee properties, Era of We will help increase
                the overall value of coffee producers.”
                <br />
                <br />
                Löfberg helped develop the Era of We concept to protect the
                future of coffee. He spent the last decade working with coffee
                farmers across the major coffee producing nations in South and
                CentralAmerica, East Africa and Asia, and he learned first-hand
                the state of the industry and what’s at stake. The Era of We was
                created to move the industry to a balanced and more
                income-sustainable model for the growers and secure the
                long-term future of coffee production.
                <br />
                <br />
                For roasters, the platform will unlock a range of new business
                opportunities through direct access to relationship building
                with outstanding, yet unknown, coffee producers from around the
                world. The platform will be a one-stop-shop to understand
                ongoing coffee estate and consumer trends, product innovations
                and prices, and bringing all of that information together to
                help develop and grow new opportunities – with the added benefit
                of marketing their own brands to a global audience.
                <br />
                <br />
                “Roasters and roaster clients are constantly on the lookout for
                new and exciting coffee producers and products to enhance their
                own brand to give consumers the best possible experience –
                unique,delicious coffee with a traceable story, said Fredrik
                Nilsson, Acting CEO of Löfbergs. Era of We provides roasters and
                roaster clients with infinite new business opportunities through
                direct access to exceptional producers around the world as a
                complement to the relationships they already have in place.”
                <br />
                <br />
                Era of We also serves as a platform for consumers who identify
                as coffee aficionados to discuss,learn and experience coffee in
                a new way.
                <br />
                <br />
                “People care more about their coffee than ever before, and those
                expectations continue to rise,” Erik Hedlund, CEO of Era of We
                adds. “Era of We builds that bridge for consumers and allows
                them to enjoy their coffee with the full knowledge of its
                characteristics and attributes. We see the coffee industry
                evolving in a manner parallel to that of the wine industry –
                where consumers seek out the coffee of specific producers for
                their characteristics, pedigree and process.”
                <br />
                <br />
                Era of We believes in a collaborative environment to enact
                positive change and welcomes the participation of anyone who
                embraces a vision to build a transparent and equitable coffee
                community. Era of We will launch first in Sweden in June, then
                it will roll out in other countries. Roasters can gain access to
                a customised suite of Era of We services and capabilities via
                monthly subscription. Visit{" "}
                <a
                  href="https://www.eraofwe.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.eraofwe.com
                </a>{" "}
                to learn more.
                <br />
                <br />
                <p className="bold mb-0">{translate.t?.about_era_of_we}</p>
                The Era of We is the world’s first end-to-end coffee platform
                that aims to disrupt and modernise the coffee supply chain and
                shift the value of the coffee brand back to the growers and
                consumers. Era ofWe is focused on connecting the world’s largest
                coffee community to transform the economics of the supply chain
                for good – creating a movement driven by a community that cares
                deeply about where coffee comes from, the product quality, and
                the future of coffee to ensure it is sustainable, profitable and
                has longevity.{" "}
                <a
                  href="https://www.eraofwe.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.eraofwe.com
                </a>{" "}
                <br />
                <br />
                <p className="bold mb-0">About Löfbergs</p>
                The Swedish based coffee group Löfbergs is a family business
                founded in 1906. We are 340 coffee lovers operating in ten core
                markets in Europe and Canada, sharing the passion for taste,
                trends and sustainability. We have a turnover of SEK 1.6 billion
                and is one of the world's largest purchasers of organic and
                Fairtrade-labelled coffee. The group includes the brands
                Löfbergs, Peter Larsen Kaffe,Percol, Green Cup, Kobbs and
                SuperBonobo.{" "}
                <a
                  href="https://en.lofbergs.se/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://en.lofbergs.se/
                </a>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default PressMedia
