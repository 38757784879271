import * as React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import PressMediaComponent from "../components/pressMedia"
import languages from "../utils/enums/languages"

const PressMedia = ({ location, pageContext: { translate } }) => (
  <Layout
    secondary
    pageContext={{ translate }}
    path="aboutus"
    location={location}
    SEO={({ data }) => (
      <SEO
        title={data.t?.creating_impactful_relationships_title}
        description={data.t?.pressmedia_metad}
        keywords={data.t?.keywords_press_media}
        languages={[
          ...languages.map(l => {
            return {
              hrefLang: l || 'en',
              href: `${process.env.GATSBY_SITE_URL}${
                l ? "/" + l + "/" : "/"
              }press-media/`,
            }
          }),
          {
            hrefLang: "x-default",
            href: `${process.env.GATSBY_SITE_URL}/press-media/`,
          },
        ]}
      />
    )}
  >
    <>
      {
        <>
          <PressMediaComponent pageContext={{ translate }}/>
        </>
      }
    </>
  </Layout>
)

export default PressMedia
